<template>
  <div class="JoinEnterpriseList">
    <div class="f-c-b head">
      <div @click="backPrivious" class="backPreviousPage f-c"><i class="el-icon-d-arrow-left"></i>返回</div>
      <el-breadcrumb style="padding: 20px 0;" class="f-c-e" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">
          <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
          <span> 当前位置： 首页</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SupplyIndex' }">供应</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/JoinEnterpriseList' }">企业信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="f-c-b functionmodule">
      <div class="f-c searchBox">
        <ChineseCharactersInput  :modelValue="searchValue" @getValue="getValue" :type="'JoinEnterPrise'"
          :placeholderValue="'请输入要搜索的内容'"></ChineseCharactersInput>
        <el-button class="searchBtn" type="primary" icon="el-icon-search" @click="search"></el-button>
      </div>
      <div class="settle f-c-e">
        若企业入驻请点击 <i class="move el-icon-d-arrow-right"></i>
        <router-link class="enterprise-join" :to="{
          name: 'EditEnterpriseInfo', query: {
            key: $UrlEncode.encode(JSON.stringify({
              type: 'join'
            }))
          }
        }"><img src="../../../assets/supply/enterpriseSettlement.png" alt=""></router-link>
      </div>
    </div>


    <template v-if="enterpriseLists && enterpriseLists.length">
      <div class="enterprise-list f-w">
        <div class="enterprise-item f-c-b" v-for="(item, index) of enterpriseLists" :key="index"
          :style="{ marginRight: (index % 2 == 0) ? '30px' : '0' }">
          <el-image class="firmImg" :src="item.eimage1" fit="fill"></el-image>
          <div class="f-cl textBox">
            <span class="name">{{ item.enterpriseName }}</span>
            <span class="city">{{ item.city }}</span>
            <div class="checkbtn" @click="toSkipfirm(item.enterpriseId)">查看</div>
          </div>
        </div>
      </div>
      <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
        @pagination="fetchData" />
    </template>
    <el-empty v-else :description="searchValue ? '搜索不到相关企业！' : '请搜索相关企业'"></el-empty>
  </div>
</template>

<script>
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin'
export default {
  name: 'JoinEnterpriseList',
  mixins: [PaginationRetention1],
  data() {
    return {
      searchValue: '',
      enterpriseLists: [],//企业列表
    }
  },
  created() {
    // let JoinEnterpriseSearchValue = this.$getStorage('JoinEnterpriseSearchValue') ? this.$getStorage('JoinEnterpriseSearchValue') : ''
    // if (JoinEnterpriseSearchValue) {
    // this.searchValue = JoinEnterpriseSearchValue;
    // this.enterpriseList(this.paging, this.pagingSize, JoinEnterpriseSearchValue)
    // }
  },
  methods: {
    getDate() {
      this.enterpriseList(this.paging, this.pagingSize, this.searchValue)
    },
    getValue(data) {
      this.searchValue = data.value
      // if (data.value) {
      // this.$setStorage('JoinEnterpriseSearchValue', data.value)
      this.search()
      // } 
      // else {
      //   this.enterpriseLists = []
      // }
    },
    // 获取企业列表
    enterpriseList(page = this.page, size = this.size, enterpriseName) {
      let that = this;
      that.$http.enterpriseList({
        applicantId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        page,
        size,
        enterpriseName
      }).then(res => {
        if (res.data.records && res.data.records.length) {
          that.enterpriseLists = res.data.records
          that.currentPage = res.data.current;//当前页数
          that.total = res.data.total//总页数
        }
      })
    },
    // 搜索企业
    search() {
      this.enterpriseLists = []
      this.enterpriseList(1, 10, this.searchValue)
    },
    // 跳转去企业
    toSkipfirm(data) {
      this.$router.push({
        name: 'SupplierInfoIndex', query: {
          key: this.$UrlEncode.encode(JSON.stringify({
            id: data, type: 'supply'
          }))
        }
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    // // 设置下一个路由meta
    // to.meta. = false; // 让A不缓存，重新请求数据
    // console.log(to)
    // next(); // 跳转到A页面
    if(to.name == 'SupplyIndex'){
      from.meta.keepAlive = false;
    }else{
      from.meta.keepAlive = true;
    }
    next();
  }
}

</script>
<style lang='less' scoped>
.JoinEnterpriseList {
  .head {
    .backPreviousPage {
      color: #0866FA;
      font-size: 16px;
      font-weight: 400;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
      color: #666666 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
      color: #0363FA !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .functionmodule {
    .searchBox {
      height: 60px;
      width: 776px;
      // border: 1px solid red;
      position: relative;

      /deep/.el-input {
        border-radius: 10px;
        // height: 60px;
      }

      /deep/.el-input__inner {
        height: 60px;
        font-size: 16px;
      }

      /deep/ .el-button {
        border-radius: 0 5px 5px 0 !important;
      }

      .searchBtn {
        position: absolute;
        right: 0;
        width: 110px;
        height: 60px;
      }
    }

    .settle {
      font-size: 16px;
      color: #333333;
      margin: 20px 0;

      .enterprise-join {
        padding: 8px 15px;
        border-radius: 20px;
        // background-color: black;
        color: #fff;
        text-align: center;
        margin-left: 20px;
      }

      /* 箭头动画 */
      @keyframes animBtn {
        0% {
          transform: translateX(0px);
        }

        50% {
          transform: translateX(5px);
        }

        100% {
          transform: translateX(0px);
        }
      }

      .move {
        animation: animBtn 0.8s linear infinite;

      }
    }
  }

  .enterprise-list {
    .enterprise-item {
      width: 580px;
      height: 160px;
      background: #F8F8F8;
      // background: #000;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 17px 20px;

      .firmImg {
        width: 140px;
        height: 140px;
        border-radius: 10px;
      }

      .textBox {
        width: 410px;
        // border: 1px solid red;

        .name {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 14px;
        }

        .city {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 24px;
        }

        .checkbtn {
          width: 80px;
          height: 40px;
          border: 1px solid #1785F6;
          border-radius: 5px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #1785F6;
          cursor: pointer;
        }
      }


    }
  }

  // .settle {
  //   font-size: 14px;
  //   color: #888;
  //   margin: 20px 0;

  //   .enterprise-join {
  //     padding: 8px 15px;
  //     border-radius: 20px;
  //     background-color: black;
  //     color: #fff;
  //     text-align: center;
  //     margin-left: 20px;
  //   }

  //   /* 箭头动画 */
  //   @keyframes animBtn {
  //     0% {
  //       transform: translateX(0px);
  //     }

  //     50% {
  //       transform: translateX(5px);
  //     }

  //     100% {
  //       transform: translateX(0px);
  //     }
  //   }

  //   .move {
  //     animation: animBtn 0.8s linear infinite;

  //   }
  // }

  // .enterprise-list {
  //   .enterprise-item {
  //     padding: 10px;
  //     border-bottom: 1px solid #ddd;

  //     .baseInfo {
  //       margin-left: 20px;

  //       span:first-child {
  //         font-size: 18px;
  //         color: #000;
  //         margin-bottom: 15px;
  //       }

  //       span:last-child {
  //         font-size: 14px;
  //         color: #888;
  //       }
  //     }
  //   }
  // }

  // .enterprise-item:hover {
  //   background-color: rgb(233, 250, 255);
  // }
}
</style>
